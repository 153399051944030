.loading-indicator::before,
.loading-indicator::after {
  position: fixed;
  width: 100%;
  left: 0;
}

.loading-indicator::before {
  content: '';
  top: 0;
  height: 100%;
  background: #121212;
  z-index: 1000;
}

.loading-indicator::after {
  content: 'Loading';
  top: 50%;
  font-size: 24px;
  color: #ffffff;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  z-index: 1001;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #202020;
}
